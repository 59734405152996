<template>
  <div class="row" style="padding-top: 20px">
  
  </div>

  <div class="row">
    <div class="col">
      <b-tabs content-class="mt-3">
        <b-tab title="Stasjonslogg" active>
          <div class="table-responsive">
            <table
              id="tableComponent"
              class="table table-bordered table-striped table-sm"
            >
              <thead>
                <tr>
                  <!-- loop through each value of the fields to get the table header -->
                  <th v-for="field in stationLogContentFields" :key="field" class="verticalOnSmallerScreens">
                    {{ field }}
                    <i class="bi bi-sort-alpha-down" aria-label="Sort Icon"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Loop through the list get the each student data -->
                <tr v-for="item in stationLogContent" :key="item">
                  <td v-for="field in stationLogContentFields" :key="field">
                    <span v-if="field == 'Stasjonsnavn'"
                      ><a :href="'/?stasjonsfilter=' + item[field]">{{
                        item[field]
                      }}</a></span
                    >
                    <span v-else>{{ item[field] }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>     
      </b-tabs>
    </div>
  </div>
</template>



<script>
import MeteorService from "../../services/meteor.service";

export default {
  name: "Statistics",

  data() {
    return {
      totalReportContent: "",
      stationLogContent: [],
      stationLogContentFields: [
        "id",
        "station_name",
        "code",
        "log_time",
        "create_time"       
      ]
    };
  },
  mounted() {


    MeteorService.getLog().then(
      (response) => {
        this.stationLogContent = response.data;
      },
      (error) => {
        this.stationLogContent =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

  },
};
</script>
<style>
@media screen and (min-width: 0px) and (max-width: 850px) {
  .hideOnSmallScreens {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 850px) {
  .verticalOnSmallerScreens {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}

@media screen and (min-width: 0px) and (max-width: 850px) {
  .smallerOnSmallScreens {
    font-size: 0.9rem;
  }
}
</style>